<template>
    <div class="app-container">
        <div class="filter-container">
            <el-form :model="searchData" :inline="true" size="small" label-width="140px">

                <el-form-item prop="title">
                  <el-input v-model="searchData.title" class='input-width' placeholder='记录标题' clearable></el-input>
                  
               </el-form-item>


                <el-form-item prop="time">

                    <el-date-picker
                        v-model="time"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="记录日期起"
                        end-placeholder="记录日期止"
                        format="yyyy-MM-dd" 
                        value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>

                </el-form-item>

                <el-form-item prop="status">
                    
                    <el-select v-model="searchData.status"  class="input-width" placeholder="启用状态" clearable>
                        <el-option v-for="item in dictionary.status" :key="item.id" :label="item.name"
                            :value="item.value">
                        </el-option>
                    </el-select>

              
               </el-form-item>

               <el-form-item prop="top">
                   
                <el-select v-model="searchData.top"  class="input-width" placeholder="优秀案例" clearable>
                        <el-option v-for="item in dictionary.status" :key="item.id" :label="item.name"
                            :value="item.value">
                        </el-option>
                    </el-select>
                   
               </el-form-item>



                <el-form-item>
                    <el-button type="primary" @click="doQueryList">查询</el-button>
                    <el-button @click="handleResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
        </div>

        <div>
            <el-button style="margin: 10px 0px 10px 20px" type="primary" icon="el-icon-plus" @click="add()">
                新增
            </el-button>
            <el-button style="margin: 10px 0px 10px 10px" type="primary" @click="handleConfigLang">
                标题多语言
            </el-button>
            <el-button style="margin: 10px 0px 10px 10px" type="primary" @click="handleTopSort">
                优秀案例排序
            </el-button>
        </div>

        <el-table ref="pagedata" v-loading="loading" :data="pageData.list"  border   style="width: 100%;">

            <el-table-column prop="falconPlanId" label="记录ID" align="center" width='100' show-overflow-tooltip></el-table-column>
            <el-table-column prop="title" label="记录标题" align="center" width='100' show-overflow-tooltip></el-table-column>
            <el-table-column prop="content" label="记录描述" align="center" width='300'  show-overflow-tooltip ></el-table-column>
            <el-table-column prop="recordDate" label="记录日期"  align="center" show-overflow-tooltip :formatter="formatDate2" width='100px'>
               
            </el-table-column>

            <el-table-column prop="icon" label="记录图片" align="center"  min-width='550' >
                <template slot-scope="scope">
                    <span v-for="(item,index) in scope.row.imageList" :key="index" style="margin: 0px 0px 0px 5px">
                        <el-image style="width: 100px; height: 59.5px" :src="ossHost + item" fit="fit"></el-image>
                    </span>
                </template>
            </el-table-column>

            <el-table-column prop="status" label="启用状态" align="center" width='80'>
                <template slot-scope="scope">
                    <el-switch  v-model="scope.row.status" active-color="#13ce66" disabled> </el-switch>
                </template>
            </el-table-column>

            <el-table-column prop="top" label="优秀案例" align="center" width='80'>
                <template slot-scope="scope">
                    <el-switch  v-model="scope.row.top" active-color="#13ce66" disabled> </el-switch>
                </template>
            </el-table-column>
           
            <el-table-column prop="operationTime" label="最近操作时间" align="center" show-overflow-tooltip :formatter="formatDate" width='100'>
               
            </el-table-column>

 

            <el-table-column prop="operator" label="操作人" align="center" width='100' show-overflow-tooltip></el-table-column>

            <el-table-column label="操作" align="center" width="100"  >
                <template slot-scope="scope">
                    <el-button @click='edit(scope.row)' type='text' size='small'>编辑</el-button>
                    <el-button @click='del(scope.row)' type='text' size='small'>删除</el-button>
                </template>
            </el-table-column>
        </el-table>


        <div class="block">
            <el-pagination
                style="margin-top: 10px"
                background
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page="searchData.page"
                :page-size="searchData.limit"
                :page-sizes="pageSizes"
                layout="total,sizes, prev, pager, next, jumper"
                :total="pageData.total"
            ></el-pagination>
        </div>
 
        <el-dialog :title="isEdit ? '编辑' : '新增'" :visible.sync="dialogFormVisible" v-if="dialogFormVisible" :close-on-click-modal="false">     
            <add-update-dialog :isEdit="isEdit" :formItem="formItem" :langs="langs" @closeDialog="closeDialog" />
        </el-dialog>
      
        <el-dialog title="标题多语言" :visible.sync="dialogConfigLangVisible" v-if="dialogConfigLangVisible" :close-on-click-modal="false">     
            <falcon-plan-config-lang :langs="langs" @closeConfigLangDialog="closeConfigLangDialog" />
        </el-dialog>

        <el-dialog title="优秀案例排序" :visible.sync="dialogTopSortVisible" v-if="dialogTopSortVisible" :close-on-click-modal="false">     
            <falcon-plan-top-sort  @closeTopSortDialog="closeTopSortDialog" />
        </el-dialog>

    </div>
</template>

<script>
import moment from 'moment';
import AddUpdateDialog from './components/AddUpdateDialog.vue';
import FalconPlanConfigLang from './components/FalconPlanConfigLang.vue';
import FalconPlanTopSort from './components/FalconPlanTopSort.vue';

const defaultFormItem= {
    falconPlanId: null,
    recordDate: null,
    sort: null,
    status: false,  
    top: false, 
    images:null,
    imageList:[],
    cover: '',
    falconPlanLangs:[]
    
}

const defaultDictionary = {  
    status: [{
        id: '1',
        name: '是',
        value: true
    }, {
        id: '2',
        name: '否',
        value: false
    }]
};

export default {
    name: 'FalconPlan',
    components: {
       moment,AddUpdateDialog,FalconPlanConfigLang,FalconPlanTopSort
    },
    data() {
        return {
            dictionary: Object.assign({}, defaultDictionary),
            time:[],
            defaultSearchData:{
                // recordDate:null,
                beginTime:null,
                endTime:null,
                title: null,
                page: 1,
                limit: 10,
            },
            searchData:{
                // recordDate:null,
                beginTime:null,
                endTime:null,
                title: null,
                page: 1,
                limit: 10,
            },
            loading: false,
            pageSizes: [10, 20, 30, 50],
            pageData: {},
            ossHost: process.env.VUE_APP_OSS_Head,
            isEdit: false,
            dialogFormVisible: false,
            formItem: Object.assign({}, defaultFormItem),
            langs: [],
            dialogConfigLangVisible:false,
            dialogTopSortVisible:false,
        };
    },
    
    created() {
        this.getLangDictItems();
    },
    mounted() {
        this.handleSearchList();
    },
    methods: {
        getLangDictItems() {
            this.$store.dispatch('getDicItems', { tag: 'lang' }).then((res) => {
                this.langs = res;               
            });
        },
        //搜索列表
        handleSearchList() {
            this.searchData.page = this.defaultSearchData.page
            this.searchData.limit = this.defaultSearchData.limit 
            this.doQueryList();
        },
        //重置
        handleResetSearch() {
            this.time = [];
            this.searchData = Object.assign({}, this.defaultSearchData);
            this.handleSearchList();
        },
        // 点击页数查询
        handleCurrentChange(val) {
            this.searchData.page = val;
            this.doQueryList();
        },
        // 点击条数查询
        handleSizeChange(val) {
            this.searchData.limit = val;
            this.doQueryList();
        },
        // 列表查询
        doQueryList() {

            // if( this.searchData.recordDate != null){
            //     this.searchData.recordDate = moment(this.searchData.recordDate).unix() * 1000;
            // }

            if (this.time != null && this.time.length > 0) {
                this.searchData.beginTime = this.time[0];
                this.searchData.endTime =   this.time[1];  
            } else {
                this.searchData.beginTime = null;
                this.searchData.endTime = null;
            }

            this.loading=true
            let temp = JSON.stringify(this.searchData, (key, value) => {
                if (value !== null && value !== '') {
                return value;
                }
            });
            let curSates = JSON.parse(temp);
            this.$store.dispatch('getFalconPlanPage', curSates).then(res => {
                this.pageData = res  
            }).finally(() => {
                this.loading = false;
            });
 
        },
        // 新增编辑页面 关闭 
        closeDialog(status,isEdit){
            this.dialogFormVisible=false
            this.formItem=JSON.parse(JSON.stringify(defaultFormItem))    
            if(status){
                if(isEdit){
                    this.doQueryList();
                }else{
                    this.handleSearchList();					
                }   
            }		
        },
        // 新增
        add(){
            this.formItem = JSON.parse(JSON.stringify(defaultFormItem))  
            this.isEdit = false;
            this.dialogFormVisible = true;
            
            this.langs.forEach(element => {
                let item = {
                    code: element.code,
                    name: element.name,
                    title: '',
                    content: '',
                    lang:element.code
                }
                this.formItem.falconPlanLangs.push(item)
            });
 
        },
        // 编辑
        edit(row){
            this.formItem = JSON.parse(JSON.stringify(row))  
            
            let falconPlanLangsMap =  new Map();
            this.formItem.falconPlanLangs.forEach((item)=>{
                falconPlanLangsMap.set(item.lang,item)
            })

            let falconPlanLangsList = [];
            this.langs.forEach(element => {
                let item = falconPlanLangsMap.get(element.code);
                if(item){
                    // 存在
                    item.code = element.code;
                    item.name = element.name;
                    falconPlanLangsList.push(item)
                }else{
                    let item = {
                        code: element.code,
                        name: element.name,
                        title: '',
                        content: '',
                        lang:element.code
                    }
                    falconPlanLangsList.push(item)
                }   
            });
            this.formItem.falconPlanLangs = falconPlanLangsList

            this.isEdit = true;
            this.dialogFormVisible = true;
        },
        // 删除
        del(row){
            this.$confirm('确认删除？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$store.dispatch('deleteFalconPlan', {id: row.falconPlanId}).then(res => { 
                    let totalPage = Math.ceil((this.pageData.total - 1) / this.searchData.limit)
                    let currentPage = this.searchData.page > totalPage ? totalPage : this.searchData.page
                    this.searchData.page = currentPage < 1 ? 1 : currentPage
                    this.$message({
                        type: 'success',
                        message: '删除成功'
                    });
                    this.doQueryList();
                });
            }).catch(() => {
            });
        },


        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        formatDate2(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD');
            }
        },
        // 标题多语言 关闭 
        closeConfigLangDialog(){
            // console.log('closeConfigLangDialog')
            this.dialogConfigLangVisible=false      		
        },
        // 标题多语言  
        handleConfigLang(){
            this.dialogConfigLangVisible=true	
        },
        // 优秀案例排序
        handleTopSort(){
            this.dialogTopSortVisible=true      
        },
        closeTopSortDialog(){
            this.dialogTopSortVisible=false	
        },
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
.filter-item {
    margin-right: 10px;
}
</style>
